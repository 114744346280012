import { Routes, Route, Navigate } from "react-router-dom";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { lazy, Suspense, ReactNode } from "react";
import { AuthContextProvider } from "./useAuthContext";

const User = lazy(() => import("../pages/user/User"));
const GuestLogin = lazy(() => import("../pages/guest-login/GuestLogin"));

const Register = lazy(() => import("../pages/register/Register"));
const Login = lazy(() => import("../pages/login/Login"));
const Logout = lazy(() => import("../pages/logout/Logout"));
const ForgotPassword = lazy(() => import("../pages/forgot-password/ForgotPassword"));
const Messages = lazy(() => import("../pages/messages/Messages"));
const Home = lazy(() => import("../pages/user/home/Home"));
const Account = lazy(() => import("../pages/user/account/Account"));
const AccountList = lazy(() => import("../pages/user/account/AccountList"));

const Product = lazy(() => import("../pages/user/products/Product"));
const Products = lazy(() => import("../pages/user/products/Products"));
const ProductsList = lazy(() => import("../pages/user/products/ProductsList"));

const Orders = lazy(() => import("../pages/user/orders/Orders"));
const Order = lazy(() => import("../pages/user/orders/Order"));
const OrdersList = lazy(() => import("../pages/user/orders/OrdersList"));
const EditOrder = lazy(() => import("../pages/user/orders/EditOrder"));
const CustomersList = lazy(() => import("../pages/user/orders/CustomersList"));
const CreateCustomer = lazy(() => import("../pages/user/orders/components/create-customer/index"));

const ImportOrders = lazy(
  () => import("../pages/user/orders/import-orders/ImportOrders")
);
const Shipment = lazy(() => import("../pages/user/shipments/Shipment"));
const DraftOrders = lazy(() => import("../pages/user/orders/DraftOrders"));
const RecentOrders = lazy(() => import("../pages/user/orders/RecentOrders"));

const firebaseConfig = {
  apiKey: "AIzaSyDfEnLstCm4QyeTDfNsASB-a3_h0sTsO0s",
  authDomain: "tavern-fb.firebaseapp.com",
  projectId: "tavern-fb",
  storageBucket: "tavern-fb.appspot.com",
  messagingSenderId: "1061672390304",
  appId: "1:1061672390304:web:89bf13290dc3f66f8843c5",
  measurementId: "G-SJFNVJ4DBF",
};

const app = initializeApp(firebaseConfig);
getFirestore(app);

const su = (element: ReactNode) => {
  return <Suspense>{element}</Suspense>;
};

export const Router = () => {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path="user" element={su(<User />)}>
          <Route path="account" element={su(<Account />)}>
            <Route path="" element={su(<AccountList />)} />
          </Route>
          <Route path="orders" element={su(<Orders />)}>
            <Route path="create-customer" element={su(<CreateCustomer />)} />

            <Route path="update-customer/:customerId" element={su(<CreateCustomer />)} />

            <Route path="view/:customerId/" element={su(<CreateCustomer />)}/>

            <Route path=":storeId">
              <Route path="create-order" element={su(<EditOrder />)}>
                <Route path=":orderId" element={su(<EditOrder />)} />
              </Route>
              <Route path="import-orders" element={su(<ImportOrders />)} />
              <Route path=":orderId" element={su(<Order />)} />
            </Route>
            <Route path="" element={su(<OrdersList />)}>
              <Route path="recent" element={<RecentOrders />} />
              <Route path="drafts" element={<DraftOrders />} />
              <Route path="" element={<Navigate to="recent" replace />} />
            </Route>
            <Route path="customers" element={<CustomersList />} />
          </Route>
          <Route path="shipments" element={su(<Orders />)}>
            <Route path=":storeId">
              <Route path=":shipmentId" element={su(<Shipment />)} />
            </Route>
            <Route path="" element={<Navigate to="/" replace />} />
          </Route>
          <Route path="inventory" element={su(<Products />)}>
            <Route path=":productId" element={su(<Product />)} />
            <Route path="" element={su(<ProductsList />)} />
          </Route>
          <Route path="messages" element={su(<Messages />)} />
          <Route path="logout" element={su(<Logout />)} />
          <Route path="" element={su(<Home />)} />
        </Route>
        <Route path="login" element={su(<Login />)} />
        <Route path="register" element={su(<Register />)} />
        <Route path="guest-login" element={su(<GuestLogin />)} />
        <Route path="forgot-password" element={su(<ForgotPassword />)} />
        <Route path="/" element={<Navigate to="/user" replace />} />
      </Routes>
    </AuthContextProvider>
  );
};
