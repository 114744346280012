import { getAuth } from "firebase/auth";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

type IAuthContext = { uid: string | null; loading: boolean };
const AuthContext = createContext<IAuthContext>({
  uid: null,
  loading: true,
});

export const useUid = (): string | null => {
  const { uid } = useContext(AuthContext);
  return uid;
};

export const useAuthLoading = (): boolean => {
  const { loading } = useContext(AuthContext);
  return loading;
};

export const useIsUnauthenticatedUser = (): boolean => {
  const context = useContext(AuthContext);
  if (context.uid != null || context.loading) {
    return false;
  }
  return true;
};

export const AuthContextProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [uid, setUid] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const auth = getAuth();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUid((prev) => (prev !== user?.uid ? user?.uid : prev));
      } else {
        setUid(null);
      }
      setLoading(false);
    });
  }, [auth]);

  const value = useMemo(() => ({ uid, loading }), [uid, loading]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
